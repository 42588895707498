import React, { useState, createContext } from "react";

export const PcContext = createContext();

export const PcProvider = (props) => {
  const [laptop, setLaptop] = useState({
    brand: "",
    issue: "",
    inches: "",
    year: "",
  });
  const [click, setClick] = useState(false);

  const addBrand = (brand) => {
    setLaptop({ ...laptop, brand });
  };

  const addIssue = (issue) => {
    setLaptop({ ...laptop, issue });
  };

  const addInches = (inches) => {
    setLaptop({ ...laptop, inches });
  };

  const addYear = (year) => {
    setLaptop({ ...laptop, year });
  };

  return (
    <PcContext.Provider
      value={[addBrand, laptop, addIssue, addInches, addYear, click, setClick]}
    >
      {props.children}
    </PcContext.Provider>
  );
};
