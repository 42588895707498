import Aos from "aos";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PcContext } from "../Components/PcProvider";
import bg1 from "../images/fixpc.png";
import bg2 from "../images/b.jpg";
import lap from "../images/lap.png";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const PcForm = () => {
  const [, laptop, , , , click] = useContext(PcContext);
  const [sending, setSending] = useState(false);

  const [openSuMeg, setOpenSuMeg] = useState(false);
  const [openErrorMsg, setOpenErrorMsg] = useState(false);
  const onCloseModal = () => setOpenSuMeg(false);
  const onCloseModalError = () => setOpenErrorMsg(false);

  const [person, setPerson] = useState({
    laptop_Name: laptop.brand,
    mac_inches: laptop.inches,
    laptop_Issue: laptop.issue,
    mac_Year: laptop.year,
    first_Name: "",
    last_Name: "",
    email_Address: "",
    phone_Number: "",
    pick_Date: "",
    pick_Time: "",
    pcImage: "",
  });

  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;

    setPerson({ ...person, [name]: value });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    // console.log({ person });
    await fetch("https://repairs.squeakfix.store/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ person }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        if (resData.status === "success") {
          setOpenSuMeg(true);
          setSending(false);
        } else if (resData.status === "fail") {
          setOpenErrorMsg(true);
          setSending(false);
        }
      })
      .then(() => {
        setPerson({
          laptop_Name: "",
          mac_inches: "",
          laptop_Issue: "",
          mac_Year: "",
          first_Name: "",
          last_Name: "",
          email_Address: "",
          phone_Number: "",
          pick_Date: "",
          pick_Time: "",
          pcImage: "",
        });
        document.getElementById("clearInput").reset();
      });
  };

  // image upload
  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "pcImage");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/squeakfix/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    setPerson({...person, pcImage: file.secure_url});
  };

  return (
    <div>
      {/* Banner */}
      <div
        className="h-60 flex justify-center items-center text-center bg-center bg-cover w-full text-white"
        style={{ backgroundImage: click ? `url(${bg2})` : `url(${bg1})` }}
      >
        <div data-aos="zoom-in">
          <h3 className="text-xl md:text-2xl font-semibold pb-3 text-white">
            Schedule Your {click ? "Mac" : "Laptop"} Repair
          </h3>
          <h4 className="font-semibold tracking-wider">
            <div className="flex justify-center">
              <Link to="/repair" className="text-secondary">
                <span className="flex justify-center gap-2">
                  {" "}
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    ></path>
                  </svg>{" "}
                  <span>Back</span>
                </span>
              </Link>{" "}
              <span className="text-white pl-2">/ Repair</span>
            </div>
          </h4>
        </div>
      </div>

      {/* Form success message */}
      <Modal open={openSuMeg} onClose={onCloseModal}>
        <div className="mr-12">
          <h3 className="font-semibold text-lg pb-1"> Message sent !!!</h3> 
          <p>We'll get back to you.</p>
        </div>
      </Modal>

      {/* Form failed message */}
      <Modal open={openErrorMsg} onClose={onCloseModalError}>
        <div className="mr-12">
          <h3 className="font-semibold text-lg pb-1"> Message failed !!!</h3>
          <p>Try again later.</p>
        </div>
      </Modal>

      <div className="xl:px-60 py-20">
        <form className="myContainer" onSubmit={handleSubmit} id="clearInput">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
              <div>
                <p className="text-lg font-semibold">Laptop Name </p>
                <input
                  type="text"
                  value={person.laptop_Name}
                  name="laptop_Name"
                  id="laptop_Name"
                  onChange={handleChange}
                  className="outline-none border-none cursor-not-allowed"
                  style={{ background: "#f8f9fa" }}
                />
              </div>
              <div className={click ? "pt-4" : "hidden"}>
                <p className="text-lg font-semibold">Mac Inches </p>

                <input
                  type="text"
                  value={person.mac_inches}
                  name="mac_inches"
                  id="mac_inches"
                  onChange={handleChange}
                  className="outline-none border-none cursor-not-allowed"
                  style={{ background: "#f8f9fa" }}
                />
              </div>
            </div>

            <div>
              <div>
                <p className="text-lg font-semibold">Laptop Issue</p>

                <input
                  type="text"
                  value={person.laptop_Issue}
                  name="laptop_Issue"
                  id="laptop_Issue"
                  onChange={handleChange}
                  className="outline-none border-none cursor-not-allowed"
                  style={{ background: "#f8f9fa" }}
                />
              </div>

              <div className={click ? "pt-4" : "hidden"}>
                <p className="text-lg font-semibold">Mac Year</p>

                <input
                  type="text"
                  value={person.mac_Year}
                  name="mac_Year"
                  id="mac_Year"
                  onChange={handleChange}
                  className="outline-none border-none cursor-not-allowed"
                  style={{ background: "#f8f9fa" }}
                />
              </div>
            </div>
            <div className="bg-gray-200 flex justify-between px-2 py-3 text-left rounded rounded-md md:col-span-2">
              <div>
                <p className="text-lg font-semibold">Estimate cost</p>
                <span className="block font-medium text-gray-600 text-base">
                  Bill before repair
                </span>{" "}
                <br />
                <span className="text-primary font-medium text-lg">
                  We'll send you a quote
                </span>
              </div>
              <img src={lap} alt="laptop" className="h-24" />
            </div>
          </div>
          {/* form */}
          <div className="mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="first_Name">First Name</label>
                <input
                  type="text"
                  id="first_Name"
                  name="first_Name"
                  placeholder="First name"
                  className="input"
                  required
                  value={person.first_Name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="last_Name">Last Name</label>
                <input
                  type="text"
                  id="last_Name"
                  name="last_Name"
                  placeholder="Last name"
                  className="input"
                  required
                  value={person.last_Name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="email_Address">Email Address</label>
                <input
                  type="email"
                  id="email_Address"
                  name="email_Address"
                  placeholder="example@gmail.com"
                  className="input"
                  required
                  value={person.email_Address}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="phone_Number">Phone Number</label>
                <input
                  type="tel"
                  id="phone_Number"
                  name="phone_Number"
                  placeholder="Phone number"
                  className="input"
                  required
                  value={person.phone_Number}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="pick_Date">Pick a date</label>
                <input
                  type="date"
                  id="pick_Date"
                  name="pick_Date"
                  className="input"
                  required
                  value={person.pick_Date}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="pick_Time">Pick a time</label>
                <input
                  type="time"
                  id="pick_Time"
                  name="pick_Time"
                  placeholder="select time"
                  className="input"
                  min="10:00"
                  max="18:00"
                  required
                  value={person.pick_Time}
                  onChange={handleChange}
                />
              </div>

              <div>
                <div>
                  <label htmlFor="pc_Image">Upload pc image</label>
                  <input
                    type="file"
                    name="file"
                    className="input"
                    accept="image/*"
                    onChange={uploadImage}
                    required
                  />
                  <input
                    type="hidden"
                    id="pcImage"
                    name="pcImage"
                    className="input"
                    value={person.pcImage}
                    onChange={handleChange}
                    required
                  />
                
                </div>
              </div>
              {/* <FileUpload/> */}
              <div>
                <h4 className="text-red-500 pb-2">
                  Please review all details before confirming laptop repair
                  schedule.
                </h4>
                <input type="checkbox" name="confirm" required />{" "}
                <span>I have confirmed all details above.</span>
              </div>
            </div>

            <div className="flex justify-center mt-10">
              <button
                type="submit"
                className="border-2 rounded rounded-3xl py-2 px-4 bg-primary text-white text-xl hover:bg-secondary transition ease-in-out duration-300 focus:border-none focus:outline-none"
              >
                {sending ? "Sending..." : "Confirm schedule"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PcForm;
