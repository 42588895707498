import Aos from "aos";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PcContext } from "../Components/PcProvider";
import bg from "../images/macBg.png";

const MacBrand = () => {
  const [addBrand] = useContext(PcContext);
  const brand1 = "Pro";
  const brand2 = "Air";
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div
        className="h-60 flex justify-center items-center text-center bg-center bg-cover w-full text-white"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div data-aos="zoom-in">
          <h3 className="text-xl md:text-2xl font-semibold pb-3 text-white">
            Choose Your Mac Brand
          </h3>
          <h4 className="font-semibold tracking-wider">
            <div className="flex justify-center">
              <Link to="/fixPc" className="text-secondary">
                <span className="flex justify-center gap-2">
                  {" "}
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    ></path>
                  </svg>{" "}
                  <span>Back</span>
                </span>
              </Link>{" "}
              <span className="text-white pl-2">/ Brands</span>
            </div>
          </h4>
        </div>
      </div>

      <div style={{ background: "rgba(0, 0, 0, 0.2)" }}>
        <div className="myContainer">
          <div
            className="flex gap-6 justify-center flex-wrap py-20 md:py-36"
            data-aos="fade-down"
          >
            <Link
            to="/pro-inches"
              onClick={() => addBrand(brand1)}
              className="cursor-pointer py-3 rounded rounded-md px-5 text-xl font-medium uppercase hover:border-primary bg-primary hover:bg-secondary"
            >
            {brand1}
            </Link>

            <Link
            to="/air-inches"
              onClick={() => addBrand(brand2)}
              className="cursor-pointer py-3 rounded rounded-md px-5 text-xl font-medium uppercase hover:border-primary bg-primary hover:bg-secondary"
            >
            {brand2}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MacBrand;
