import about from "../images/about.png";
import partner from "../images/partner.svg";
import experience from "../images/Experience.svg";
import record from "../images/record.svg";
import we from "../images/we.svg";
import banner from "../images/banner.jpg"
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Corporate() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5" style={{ backgroundImage: `url(${banner})` }}>
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-semibold pb-2">
            Corporate Overview
          </h3>
          <h4 className="font-semibold tracking-wider">
            <Link to="/otherService" className="text-secondary">
              Back
            </Link>{" "}
            / Overview
          </h4>
        </div>
      </div>
      <div className="myContainer pt-8">
        <ChildCompCorporate>
          <div className="service-item">
            <img src={about} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Who We Are</h3>
              <p>
                Squeakfix Technologies Nig. Ltd. is a Technology firm based in
                Lagos. We have extensive experience in many diverse areas of
                both software development and hardware repairs/acquisition. Our
                experienced staff of professionals has worked with a wide array
                of platforms, languages, and tools in the course of our various
                projects and contracts. We have acquired more than 200 laptops,
                78 desktops, 343 phones and a host of other gadgets for our
                clients over the years. We have successfully repaired more than
                579 devices since inception.
              </p>
            </div>
          </div>
        </ChildCompCorporate>
        <ChildCompCorporate>
          <div className="service-item">
            <img src={we} alt="our services" className="h-96 lg:pl-10" />
            <div>
              <h3 className="service-title">What We Do</h3>
              <p>
                Quite simply: we develop software, create websites, repair
                computers and phones and acquire gadgets for clients including
                setup. Our clients are thrilled with our services because of the
                comfort we provide. We bring everything to you. We handle our
                logistics internally and all payments can be refunded if the
                client is not satisfied with a service. Our software and
                websites are industry standard and all our gadgets are imported
                directly from Amazon.
              </p>
              <p className="py-3">
                SQUEAKFIX’s services are available on both a fixed and variable
                cost basis, Being a “technology” firm, SQUEAKFIX is able to
                provide our clients with a variety of services. A few of the
                services available from SQUEAKFIX includes:
              </p>
              <ul className="font-bold">
                <li>System Architecture and Design &#x2714;</li>
                <li>Software Development &#x2714;</li>
                <li>Website Development &#x2714;</li>
                <li>Project Management &#x2714;</li>
                <li>IT Support and Network Administration &#x2714;</li>
                <li>Phone and Tablet repairs &#x2714;</li>
                <li>Device Acquisition and Setup &#x2714;</li>
              </ul>
            </div>
          </div>
        </ChildCompCorporate>

        <ChildCompCorporate>
          <div className="service-item">
            <img src={partner} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Partnerships</h3>
              <p>
                Our goal at SQUEAKFIX Technologies is to become our client's
                first choice for software services and device
                acquisition/management. To accomplish this, we strive to exceed
                our client's expectations. SQUEAKFIX clients cannot simply be
                satisfied with the job we do… they must be impressed. In this
                manner, SQUEAKFIX strives to build lasting partnerships and
                ensure client satisfaction. It is upon this foundation that our
                clients are confident in returning for future Technological
                needs.
              </p>
            </div>
          </div>
        </ChildCompCorporate>

        <ChildCompCorporate>
          <div className="service-item">
            <img
              src={experience}
              alt="our services"
              className="h-80 lg:pl-10"
            />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Experience</h3>
              <p>
                Providing quality services begins with experience and ends with
                dedication. SQUEAKFIX Technologies' professionals have years of
                experience in development, and testing of countless software
                projects, Repair of major brands of phones (Iphone, Samsung,
                Huawei, Tekno, Infinix, Nokia etc) and laptops (Dell, Hp, Asus,
                Lenovo, Alienware, Chromebooks etc). It is this experience,
                along with our dedication to giving clients the best quality
                service possible, which sets SQUEAKFIX apart from other firms.
              </p>
            </div>
          </div>
        </ChildCompCorporate>

        <ChildCompCorporate>
          <div className="service-item">
            <img src={record} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">A Proven Track Record</h3>
              <p>
                SQUEAKFIX Technologies has a proven track record of providing
                high quality software and Complete repair and acquisition
                services. Our client list includes Key corporations in Lagos,
                government agencies, and small businesses. Our experience is
                broad and spans both the private and public sector.
              </p>
            </div>
          </div>
        </ChildCompCorporate>
      </div>
    </>
  );
}

// childComp

function ChildCompCorporate({ children }) {
  return <div data-aos="fade-up">{children}</div>;
}

export default Corporate;
