import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";

function Navbar() {
  return (
    <nav className="flex justify-around items-center bg-white sticky top-0 shadow-sm">
      <NavLink to="/">
        <img src={logo} alt="logo" className="pb-2 pt-1" />
      </NavLink>
      <NavLink
        to="/contact"
        className="text-lg font-semibold tracking-wider hover:text-secondary"
      >
        Contact us
      </NavLink>
    </nav>
  );
}

export default Navbar;
