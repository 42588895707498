import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import banner from "../images/contact_banner.jpg";

function Contact() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div
        className="text-white flex justify-center text-center items-center px-5 bg-center bg-cover bg-blend-overlay"
        style={{ backgroundImage: `url(${banner})`, height: "55vh"}}
      >
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-semibold pb-2">
            Contact Us
          </h3>
          <h4 className="font-semibold tracking-wider">
            <Link to="/" className="text-secondary">
              Home
            </Link>{" "}
            / Contact
          </h4>
        </div>
      </div>
      <div className="pb-8" style={{ background: "rgba(0, 0, 0, 0.2)" }}>
        <div className="myContainer" data-aos="fade-down">
          <h2 className="text-center pt-10 text-2xl font-semibold">
            Get in touch with us
          </h2>
          <div className="flex justify-center items-center mb-20 mt-10">
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-4 md:gap-4">
              <div className="contact-box">
                <span className="flex justify-center pb-5">
                  <svg
                    className="w-12 h-12 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    ></path>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg>
                </span>

                <p>16A Eru-Ifa street, Ikate, Lekki, Lagos.</p>
              </div>

              <div className="contact-box">
                <span className="flex justify-center pb-5">
                  <svg
                    className="w-12 h-12 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    ></path>
                  </svg>
                </span>

                <a
                  href="tel: +234 812 052 6774"
                  className="block pb-1 hover:text-white"
                >
                  +234 812 052 6774
                </a>
                <a href="tel: +2348132393485" className="hover:text-white">
                  {" "}
                  +2348132393485
                </a>
              </div>

              <div className="contact-box">
                <span className="flex justify-center pb-5">
                  <svg
                    className="w-12 h-12 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                </span>

                <a
                  href="mailto: info@squeakfix.com.ng"
                  className="block pb-1 hover:text-white"
                >
                  info@squeakfix.com.ng
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
