import React from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import bg from "../images/home.jpg";
import Particles from "react-particles-js";

function OtherService() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      <Particles
        className="particle"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            shape: {
              type: "square",
              stroke: {
                width: 6,
                color: "#eaa249",
              },
            },
          },
        }}
      />

      <div
        className="otherS flex justify-center items-center bg-center bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
          <div className="wrap">
          <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:gap-4 md:gap-4 sm:gap-4"
          data-aos="fade-down"
        >
          <Link to="/aServices" className="home-box">
            Available Services
          </Link>
          <Link to="/corporate" className="home-box">
            Corporate Overview
          </Link>

          <Link to="/experience" className="home-box">
            Experience
          </Link>

          <Link to="/rate" className="home-box">
            Rates and Billing
          </Link>
        </div>
          </div>
      </div>
    </>
  );
}

export default OtherService;
