import React from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import bg from "../images/home.jpg";
import Particles from "react-particles-js";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      <Particles
        className="particle"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            shape: {
              type: "square",
              stroke: {
                width: 6,
                color: "#eaa249",
              },
            },
          },
        }}
      />
      <div>
        <div
          className="home flex justify-center items-center bg-center bg-cover"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="wrap" data-aos="zoom-in">
            <Link to="/fixPc" className="home-box flex-col">
              FIX PC
            </Link>

            <Link to="/progress" className="home-box flex-col">
              SHOP NOW
            </Link>

            <Link to="/otherService" className="home-box flex-col">
              OTHER SERVICES
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
