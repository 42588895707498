import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";

const BackToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (pageYOffset > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [pageYOffset]);

  const scroll = () => window.scrollTo({ top: 0, behaviour: "smooth" });

  if (!visible) {
    return false;
  }
  return (
    <div className="scroll-to-top mr-5 text-xl fixed rounded-full flex justify-center bg-secondary text-white cursor-pointer" onClick={scroll}>

      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-arrow-up-circle icon" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
</svg>
    </div> 
  );
};

export default BackToTop;
