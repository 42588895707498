import PropTypes from "prop-types";
import BackToTop from "./BackToTop";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { PcProvider } from "./PcProvider";
import StartTop from "./StartTop";


const propTypes = {
  children: PropTypes.any.isRequired,
};
const Layout = ({ children }) => {
 
  return (
    <PcProvider>
      <Navbar />
      <BackToTop />
      <StartTop />
      <main>{children}</main>
      <Footer/>
    </PcProvider>
  );
};

Layout.propTypes = propTypes;

export default Layout;
