import { Link } from "react-router-dom";
import devImage from "../images/developmet.svg";
import projectImage from "../images/project.svg";
import repairImage from "../images/repair.svg";
import banner from "../images/banner.jpg"
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function AvailableS() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  return ( 
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5" style={{ backgroundImage: `url(${banner})` }}>
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-semibold pb-2">
            Available Services  
          </h3>
          <h4 className="font-semibold tracking-wider">
            <Link to="/otherService" className="text-secondary">
              Back
            </Link>{" "}
            / Service
          </h4>
        </div> 
               
      </div>
      <div className="myContainer pt-8">
        <div
          className="grid grid-cols-1 md:grid-cols-2 text-justify gap-6 mt-5"
          data-aos="fade-up"
        >
          <div>
            <p>
              SQUEAKFIX Software provides its clients with a wide range of
              services spanning the entire development process. Clients can
              utilize SQUEAKFIX for the entire development cycle or only for
              selected portions that require additional support and
              consultation. Our services are available on an hourly or project
              basis (see Rates and Billing).
            </p>
          </div>
          <div>
            <p>
              At SQUEAKFIX, we draw upon our vast experience to provide our
              customers with the best possible services. As a technology firm,
              there are no limits on what projects we can undertake. However,
              most development efforts require the same basic types of services.
              Some of the common services available from SQUEAKFIX include:
            </p>
          </div>
        </div>
        <ChildCompA>
          <div className="service-item">
            <img src={devImage} alt="our services" />
            <div className="lg:pt-20 md:pt-5">
              <h3 className="service-title">
                Desktop/Mobile Software Development
              </h3>
              <p>
                The key to quality development is sharp people with a
                well-thought-out plan. Good organization allows developers to
                modularize the development process. This results in rapid
                implementation of a system that can be easily tested and
                expanded for future versions. SQUEAKFIX provides a highly
                qualified technical staff with years of software development
                experience.
              </p>
            </div>
          </div>
        </ChildCompA>

        <ChildCompA>
          <div className="service-item">
            <img src={projectImage} alt="our services" className="h-96" />
            <div className="lg:pt-20 sm:pt-5">
              <h3 className="service-title">Project Management</h3>
              <p>
                SQUEAKFIX clients often require additional consulting and
                managerial support to supplement their in-house development
                efforts. SQUEAKFIX is uniquely qualified to provide this vital
                support. The professionals at SQUEAKFIX have worked on countless
                software development efforts, and as a result are well equipped
                to quickly anticipate and address problem areas on a given
                project. SQUEAKFIX can serve as troubleshooters or, if need be,
                assume management of an entire project.
              </p>
            </div>
          </div>
        </ChildCompA>
        <ChildCompA>
          <div className="service-item">
            <img src={repairImage} alt="our services" className="h-80" />
            <div className="lg:pt-20">
              <h3 className="service-title">
                Gadget Repair and Maintenance Services
              </h3>
              <h3 className="service-title">Gadget Acquisition</h3>
            </div>
          </div>
        </ChildCompA>
      </div>
    
    </>
  );
}

// child comp

function ChildCompA({ children }) {
  return <div data-aos="fade-up">{children}</div>;
}

export default AvailableS;
