import Aos from "aos";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PcContext } from "../Components/PcProvider";
import bg1 from "../images/fixpc.png";
import bg2 from "../images/b.jpg";
const PcIssue = () => {
  const [, , addIssue, , , click] = useContext(PcContext);
  let issues = [
    "Screen replacement",
    "Battery replacement",
    "Keyboard replacement",
    "Not powering",
    "Not charging ",
    "No display",
  ];
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div
        className="h-60 flex justify-center items-center text-center bg-center bg-cover w-full text-white"
        style={{ backgroundImage: click ? `url(${bg2})` : `url(${bg1})` }}
      >
        <div data-aos="zoom-in">
          <h3 className="text-xl md:text-2xl font-semibold pb-2 text-white">
            What's the Issue with your {click ? "Mac" : "PC"}
          </h3>
          <h4 className="font-semibold tracking-wider">
            <div className="flex justify-center">
              <Link to="/fixPc" className="text-secondary">
                <span className="flex justify-center gap-2">
                  {" "}
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    ></path>
                  </svg>{" "}
                  <span>Back</span>
                </span>
              </Link>{" "}
              <span className="text-white pl-2">/ Issue</span>
            </div>
          </h4>
        </div>
      </div>
      <div style={{ background: "rgba(0, 0, 0, 0.2)" }}>
        <div className="myContainer lg:px-52">
          <ul
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 py-20 md:py-28"
            data-aos="fade-down"
          >
            {issues.map((issue) => {
              return (
                <Link
                  to="/schedule"
                  key={issue}
                  onClick={() => addIssue(issue)}
                  className="cursor-pointer py-3 rounded rounded-md px-5 text-xl font-medium capitalize hover:border-primary bg-primary hover:bg-secondary"
                >
                  {issue}
                </Link>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default PcIssue;
