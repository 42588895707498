import { Route, Switch } from "react-router-dom"
import Layout from "./Components/Layout"
import Home from "./Pages/Home"
import FixPc from "./Pages/FixPc"
import ShopNow from "./Pages/ShopNow"
import OtherService from "./Pages/OtherService"
import Corporate from "./Pages/Corporate"
import Experience from "./Pages/Experience"
import AvailableS from "./Pages/AvailableS"
import Rates from "./Pages/Rates"
import WorkProgress from "./Pages/WorkProgress"
import Contact from "./Pages/Contact"
import PageNotFound from "./Pages/404"
import Brand from "./Pages/WBrand"
import PcIssue from "./Pages/PcIssue"
import PcForm from "./Pages/PcForm"
import MacBrand from "./Pages/MacBrand"
import ProInches from "./Pages/ProInches"
import AirInches from "./Pages/AirInches"
import MacYear from "./Pages/MacYear"

function App() {
    return (
       <Layout>
           <Switch>
               <Route exact path="/"><Home/> </Route>
               <Route path="/fixPc"> <FixPc /></Route>
               <Route path="/window-brand"> <Brand /></Route>
               <Route path="/mac-brand"> <MacBrand /></Route>
               <Route path="/pro-inches"> <ProInches /></Route>
               <Route path="/air-inches"> <AirInches /></Route>
               <Route path="/mac-year"> <MacYear /></Route>
               <Route path="/repair"> <PcIssue /></Route>
               <Route path="/schedule"> <PcForm /></Route>
               <Route path="/shopNow"> <ShopNow/> </Route>
               <Route path="/otherService"> <OtherService/> </Route>
               <Route path="/corporate"> <Corporate/>  </Route>
               <Route path="/experience"> <Experience/> </Route>
               <Route path="/aServices"> <AvailableS/> </Route>
               <Route path="/rate"> <Rates/> </Route>
               <Route path="/progress"> <WorkProgress/> </Route>
               <Route path="/contact"> <Contact /> </Route>
               <Route><PageNotFound/></Route>
           </Switch>
       </Layout>
    )
}

export default App
