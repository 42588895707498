import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../images/fixpc.png";
import Particles from "react-particles-js";
import mac from "../images/apple.png";
import windows from "../images/window.png";
import Aos from "aos";
import { PcContext } from "../Components/PcProvider";

function FixPc() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);

  const [, , , , , , setClick] = useContext(PcContext);
  return (
    <>
      <Particles
        className="particle"
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 900,
              },
            },
            shape: {
              type: "square",
              stroke: {
                width: 6,
                color: "#eaa249",
              },
            },
          },
        }}
      />
      <div>
        <div
          className="home flex justify-center items-center bg-center bg-cover"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="wrap" data-aos="zoom-in">
            <Link
              to="/window-brand"
              className="home-box flex gap-4"
              onClick={() => setClick(false)}
            >
              <span className="pt-2"> WINDOWS</span>{" "}
              <img src={windows} alt="" className="h-12 w-12" />
            </Link>
            <div className="my-10"></div>
            <Link
              to="/mac-brand"
              className="home-box flex gap-14"
              onClick={() => setClick(true)}
            >
              <span className="pt-2">MAC</span>{" "}
              <img src={mac} alt="" className="h-12" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default FixPc;
