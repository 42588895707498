import bill from "../images/billing.svg";
import hourly from "../images/hourly.svg";
import value from "../images/value.svg";
import payment from "../images/payment.png";
import { Link } from "react-router-dom";
import banner from "../images/banner.jpg"
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function Rates() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5" style={{ backgroundImage: `url(${banner})` }}>
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-semibold pb-2">
            Rates and Billing
          </h3>
          <h4 className="font-semibold tracking-wider">
            <Link to="/otherService" className="text-secondary">
              Back
            </Link>{" "}
            / Billing
          </h4>
        </div>
      </div>
      <div className="myContainer pt-8">
        <p className="text-center pt-1 text-lg">
          Squeakfix Technologies Nig. Ltd. has two general methods of payment
          for its services: fixed cost and hourly.
        </p>
        <ChildCompRate>
          <div className="service-item">
            <img src={bill} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Fixed Cost</h3>
              <p>
                Fixed cost is used primarily when there is a clearly defined
                project. For example, the goals, tasks, and scope of the project
                are well defined. This method is often used for our software
                application development efforts, Gadget acquisition, Gadget
                repair and maintenance services.. The end project cost is
                directly proportional to the complexity and scale of the
                application and are negotiated on a project-by-project basis.
              </p>
            </div>
          </div>
        </ChildCompRate>

        <ChildCompRate>
          <div className="service-item">
            <img src={hourly} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Hourly</h3>
              <p>
                The hourly method is used when these items are less clear. For
                example, on an R{"&"}D project, or when it is not practical to
                thoroughly define a project up-front due to time limitations or
                lack of information. This method is more common in the EDA
                industry. Hourly rates vary based on type of work, the
                experience level of the person performing the work, and the
                length of the engagement. Engagement terms are generally:
                Individual hourly, 3 months, 6 months, and 12 months, with rates
                decreasing for longer term engagements. Rates range are from
                #2,500/hr for low-end documentation and testing up to #20,000/hr
                and higher for toplevel project management and high-end
                technical development.
              </p>
            </div>
          </div>
        </ChildCompRate>

        <ChildCompRate>
          <div className="service-item">
            <img src={value} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-20 sm:pt-5">
              <h3 className="service-title">Maximize Value</h3>
              <p>
                SQUEAKFIX Software's rates are structured to maximize the value
                for our clients. Rates are scaled according to the type of work
                done and the experience of the individual doing the work.
                SQUEAKFIX staffs projects with the proper combination of team
                members to maximize the return for the customer thus saving them
                time and money.
              </p>
            </div>
          </div>
        </ChildCompRate>
        <ChildCompRate>
          <div className="service-item">
            <img src={payment} alt="our services" className="h-96 lg:pl-10" />
            <div className="lg:pt-20 sm:pt-5">
              <h3 className="service-title">Billing and Payment</h3>
              <p>
                SQUEAKFIX Software's standard billing practice is to invoice
                semi-monthly with payment due within two weeks of the invoice
                date. This practice is used on all hourly contracts unless a
                separate payment arrangement has been negotiated. Billing terms
                on fixed price contracts is always defined within the project's
                contract. These terms generally reflect a percentage up-front, a
                percentage upon completion of a significant milestone, a
                percentage upon delivery, and a percentage as a retainer until
                final acceptance by the client.
              </p>
            </div>
          </div>
        </ChildCompRate>
      </div>
    </>
  );
}

// childComp

function ChildCompRate({ children }) {
  return <div data-aos="fade-up">{children}</div>;
}

export default Rates;
