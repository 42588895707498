import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Dynamic from "../images/web.png";
import Experts from "../images/Experts.svg";
import Gadget from "../images/Gadget.svg";
import repair from "../images/Gadget-Repair.svg";
import web from "../images/World_wide web.svg";
import IT from "../images/IT.svg";
import banner from "../images/banner.jpg"

function Experience() {
  useEffect(() => {
    Aos.init({ duration: 1100 });
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5" style={{ backgroundImage: `url(${banner})` }}>
        <div data-aos="zoom-in">
          <h3 className="text-2xl md:text-4xl font-semibold pb-2">
            Experience
          </h3>
          <h4 className="font-semibold tracking-wider">
            <Link to="/otherService" className="text-secondary">
              Back
            </Link>{" "}
            / Experience
          </h4>
        </div>
      </div>
      <div className="myContainer pt-8">
        <ChildCompExp>
          <div className="service-item">
            <img src={web} alt="our services" className="h-96 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Web Experience</h3>
              <p>
                Although Desktop/Mobile Software and Web development share many
                common traits (GUI design, backend database hooks, etc.), there
                are also many significant differences in the underlying
                application architectures that one must fully understand to
                create a successful Web-based application. Over the years
                SQUEAKFIX has gained this type of experience through our
                projects in both the Windows and Linux environments. SQUEAKFIX
                has Web-enabled existing applications, developed numerous Web-
                based systems, utilized various backend databases, and
                setup/administered: Web servers, email servers, FTP sites, and
                firewalls. The Web development market is a fast-paced
                environment where technology is always changing. We stay abreast
                of the latest changes in tools and languages including XML,
                Java, and others.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <img src={repair} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">
                {" "}
                Gadget Repair and Maintenance Experience
              </h3>
              <p>
                At SQUEAKFIX, we place key emphasis on maintenance culture and
                we often time include this in our billings when clients acquire
                products from us. We have trained and qualified experts who have
                undergone training in the various brand companies so as to
                properly provide support and repair services to our clients. We
                have a 98.75% success rate when fixing products we acquired for
                clients and those brought in newly.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <img src={Gadget} alt="our services" className="h-80 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-5">
              <h3 className="service-title">Gadget Acquisition Experience</h3>
              <p>
                Over the years SQUEAKFIX has acquired a number of gadgets for
                clients based on an understanding of the required needs of the
                client and their unique individual preferences. We specialize in
                quality brands and place utmost importance in “value for money”
                for our clientele. We under go specialized research on the exact
                product that matches our customer’s needs and make an
                appropriate recommendation when necessary, due to our high
                number of acquisitions, we have a lot of supply companies and
                distributors of key gadget brands as our partners.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <img
              src={Experts}
              alt="our services"
              className="h-80 lg:pl-10 lg:pt-4"
            />
            <div>
              <h3 className="service-title">Areas of Expertise</h3>
              <p>
                SQUEAKFIX consultants have experience in many different aspects
                of the Technological realm. Because we are a technology firm,
                there are no limits on what projects we can undertake in this
                area. It is this ability that has enabled SQUEAKFIX to
                accumulate experience in so many diverse areas. A few of these
                include:
              </p>
              <ul className="font-bold mt-2">
                <li>
                  Application Design, Development, and Testing (GUI, Database,
                  etc.) &#x2714;
                </li>
                <li>Web Development (Internet/Intranet) &#x2714;</li>
                <li>Web-Enabling Applications &#x2714;</li>
                <li>ERP Software &#x2714;</li>
                <li>
                  Point-of-Sale and General Business Services Applications
                  &#x2714;
                </li>
                <li>System Architecture and Implementation &#x2714;</li>
                <li>Systems Integration &#x2714;</li>

                <li>Object-Oriented Design and Analysis &#x2714;</li>
                <li>Gadget Repairs &#x2714;</li>
                <li>Gadget Acquisitions &#x2714;</li>
              </ul>
              <p>
                No matter what your Technological challenge, SQUEAKFIX
                consultants are a valuable resource.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <div className="lg:pt-10 sm:pt-12">
              <img src={IT} alt="our services" className="h-96 lg:pl-10" />
            </div>
            <div>
              <h3 className="service-title">Software Development Experience</h3>
              <p className="pb-3">
                In the Software applications arena, SQUEAKFIX developers have
                worked on everything from windows personal nutrition software,
                transport apps on Android and iOS, GPS satellite tracking
                systems and many more during the course of our development
                experience. SQUEAKFIX has implemented countless applications,
                utilized all aspects of the MS Windows environment (such as
                ODBC, DDE, OLE, DLLs, drivers and threading), and has gained
                experience with all the major integrated development
                environments (IDEs, such as Android studio, MS Visual Studio,
                Xcode and Netbeans) In past projects, SQUEAKFIX has ported
                applications, interfaced with external hardware (scanners, data
                collection devices, etc.), and implemented distributed network
                solutions.
              </p>
              <p>
                The first step to developing a Software application is designing
                the graphical user interface (GUI). An effective GUI is critical
                to the acceptance and success of any Software application.
                Failure to produce an effective GUI will kill even the best
                application. SQUEAKFIX has years of experience in GUI design,
                both for the Windows operating system, Android, IOS and Java.
                SQUEAKFIX consultants work very closely with our clients to
                design the best GUI possible for their specific application.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <img src={Dynamic} alt="our services" className="h-96 lg:pl-10" />
            <div className="lg:pt-10 sm:pt-1">
              <h3 className="service-title">Dynamic</h3>
              <p>
                Our experience, in conjunction with our quality personnel,
                enables SQUEAKFIX to be dynamic enough to tackle almost any
                software development project, Fix any device and acquire any
                gadget.
              </p>
              <p className="pt-2">
                SQUEAKFIX has a proud history of utilizing experience to provide
                our clients with the best services possible. This difficult task
                is accomplished by applying experienced personnel to every
                project. SQUEAKFIX was founded on the principle of gathering an
                elite group of software engineers and professionals, and
                leveraging their collective abilities. Naturally, each of these
                "producers" brings their own particular specialization and
                experience to the table. Once they join the SQUEAKFIX team, we
                cross-train our consultants to diversify and increase their
                overall skills. The result for our clients is a group of
                individuals that can genuinely contribute and have a positive
                impact on development projects.
              </p>
            </div>
          </div>
        </ChildCompExp>

        <ChildCompExp>
          <div className="service-item">
            <div>
              <h3 className="service-title">Languages, Platforms, and Tools</h3>
              <p>
                {" "}
                SQUEAKFIX consultants have gained extensive experience with a
                wide variety of languages, platforms, and tools during the
                course of our projects. In addition, SQUEAKFIX consultants have
                become experts at adjusting quickly when faced with new
                development environments. We are able to rapidly “come up to
                speed” on existing projects. The following lists just a few of
                the languages, platforms, and tools with which we have
                experience:
              </p>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>General Languages:</td>
                    <td>
                      C#, C++, JAVA, Javascript, PHP, Python, shell scripting,
                      and more
                    </td>
                  </tr>
                  <tr>
                    <td>Web Technologies:</td>
                    <td>
                      .NET, XML, HTML, CGI, Java, JSP, J2EE, J2ME, ASP, VBS, Web
                      Servers, Mail Servers, Firewalls, IIS, Apache Tomcat and
                      more
                    </td>
                  </tr>
                  <tr>
                    <td>Frameworks:</td>
                    <td>
                      CRMs, SAPs, Visual Basic, CI, Laravel, NodeJs and more
                    </td>
                  </tr>
                  <tr>
                    <td>Platforms: </td>
                    <td>
                      Windows, Unix (Linux, MacOS, SunOS, AIX), Handhelds
                      (Windows, Android, iOS)
                    </td>
                  </tr>
                  <tr>
                    <td>Database Engines {"&"} APIs:</td>
                    <td>
                      MySQL, PosgreSQL, Amazon DynamoDB, Big Query, ODBC, JDBC,
                      SQL, Oracle, SWIG, and more
                    </td>
                  </tr>
                  <tr>
                    <td>Networking: </td>
                    <td>
                      Wireless, VOIP, Telephony, LANs/WANs, TCP/IP, Windows
                      Networking, Novell; DNS, NIS, VPNs, VNC, and more
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ChildCompExp>
      </div>
    </>
  );
}

// childComp

function ChildCompExp({ children }) {
  return <div data-aos="fade-up">{children}</div>;
}

export default Experience;
