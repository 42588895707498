import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

function WorkProgress() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 4000);
  }, [history]);
  return (
    <div>
      <div className="work w-full flex justify-center items-center text-center myContainer">
        <div>
          <h1 className="lg:text-5xl md:text-4xl text-2xl pb-2 font-extrabold text-primary">
            Coming soon...
          </h1>
          <p className="text-lg font-medium">
            Please come back after some time.. Thanks!!
          </p>
        </div>
      </div>
    </div>
  );
}

export default WorkProgress;
